import React, { useContext } from 'react';
import './services.scss';
import { Link } from "react-router-dom";
import { AppContext } from '../../services/translationContext';

export const Services = () => {
    const { services: {TranslationsService} } = useContext(AppContext);
    document.title = `${TranslationsService.labels(`menu.services.label`)} | ${TranslationsService.getMainInfoCompany('name')}`;
    const servicesList = TranslationsService.labels('services');

  return (
    <div className="section-home">
      <section className='main-hero syd-hero position-relative' style={{backgroundImage:`url(${TranslationsService.labels('hero_sections.services.img_path')})`}}>
        <div className='my-auto mx-0'>
            <h1 className='syd-title light text-uppercase'>{TranslationsService.labels(`menu.services.label`)}</h1>
            {
                TranslationsService.labels('hero_sections.services.text') &&
                <p className='dark-mode-text fs-1 m-0'>{TranslationsService.labels('hero_sections.services.text')}</p>
            }
        </div>
      </section>
        <div className='container-fluid'>
            {
                Object.keys(servicesList).map((area,indice) => {
                    return (
                    <div key={indice}>
                        <div className='m-2 title-service-sticky'>
                            <Link to={`/services/${area}`} className='text-deco-none d-flex gap-3 align-items-center title-area' style={{width:'max-content'}}>
                                <h2 className='syd-title white m-0 fw-bold transition-03s-eio' style={{color:'#fece2f', fontSize:'4rem', zIndex: 9999}}>{servicesList[area].title}</h2>
                                {/* <svg viewBox="0 0 10 11.55" style={{height:'1rem'}} className="box-arrow-title-service">
                                    <polygon className="arrow-title-service transition-03s-eio" points="10 5.77 0 0 0 11.55 10 5.77"/>
                                </svg> */}
                            </Link>
                        </div>
                        <div>
                            {/* {
                                Object.keys(servicesList[area]).map((subbu,i) => (
                                servicesList[area][subbu].title && 
                                    <div className='col-sm-6 col-lg-4' key={i}>
                                        <Link to={`/services/${area}/${subbu}`} className='text-deco-none'>
                                            <div className='syd-box small'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div>
                                                        <h4 className='syd-title small light'>{servicesList[area][subbu].title}</h4>
                                                    </div>
                                                    <div>
                                                        <svg viewBox="0 0 10 11.55" className='box-arrow-item'>
                                                            <polygon className="arrow-item" points="10 5.77 0 0 0 11.55 10 5.77"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <p className='syd-paragraph' dangerouslySetInnerHTML={{ __html: servicesList[area][subbu].desc }}></p>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                            } */}
                            {
                                TranslationsService.labels('services')[area].items ?
                                (
                                Object.keys(TranslationsService.labels('services')[area].items).map((_sub, i) => (
                                    <div key={i}>
                                        <Link to={`/services/${area}/${_sub}`} className='text-deco-none'>
                                            <h3 className='dark-mode-text m-0 fw-bold transition-03s-eio fs-3 pb-3'>{TranslationsService.labels('services')[area].items[_sub].title}</h3>
                                        </Link>
                                        <div className='container px-md-5'>
                                            <div className='row'>
                                            {
                                                TranslationsService.labels('services')[area].items[_sub] ?
                                                (
                                                Object.keys(TranslationsService.labels('services')[area].items[_sub]).map((_subItem, j) => (
                                                    typeof TranslationsService.labels('services')[area].items[_sub][_subItem] === 'object' &&
                                                    TranslationsService.labels('services')[area].items[_sub][_subItem].title ?
                                                    (
                                                    <div className='col-md-4 col-sm-12 p-4' key={j}>
                                                        <div className='syd-card-container'>
                                                          <div className='image-background' style={{backgroundImage: `url(${TranslationsService.labels('services')[area].items[_sub][_subItem].image})`}}/>
                                                          <div className='syd-content-card'>
                                                            <h4>{TranslationsService.labels('services')[area].items[_sub][_subItem].title}</h4>
                                                            <Link to={`/services/${area}/${_sub}/${_subItem}`} className='text-deco-none'>
                                                              <div className='syd-button-card'>
                                                                {TranslationsService.labels('explore_more')}
                                                              </div>
                                                            </Link>
                                                          </div>
                                                        </div>
                                                        {/* <Link to={`/services/${area}/${_sub}/${_subItem}`} className='text-deco-none'>
                                                            <div className='syd-box small syd-box-service'>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <div>
                                                                        <h5 className='syd-title small light'>{TranslationsService.labels('services')[area].items[_sub][_subItem].title}</h5>
                                                                    </div>
                                                                    <div>
                                                                        <svg viewBox="0 0 10 11.55" className='box-arrow-item'>
                                                                            <polygon className="arrow-item" points="10 5.77 0 0 0 11.55 10 5.77"/>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <p className='syd-paragraph' dangerouslySetInnerHTML={{ __html: TranslationsService.labels('services')[area].items[_sub][_subItem].desc }}></p>
                                                            </div>
                                                        </Link> */}
                                                    </div>
                                                    ) : null
                                                ))
                                                ) : null
                                            }
                                            </div>
                                        </div>
                                        {/* <div className='row row-gap-3'>
                                        {
                                            TranslationsService.labels('services')[area].items[_sub] ?
                                            (
                                            Object.keys(TranslationsService.labels('services')[area].items[_sub]).map((_subItem, j) => (
                                                typeof TranslationsService.labels('services')[area].items[_sub][_subItem] === 'object' &&
                                                TranslationsService.labels('services')[area].items[_sub][_subItem].title ?
                                                (
                                                <div className={`col-sm-6 col-lg-${((j - 2) % 7) < 4 ? '3':'4'}`} key={j}>
                                                    <Link to={`/services/${area}/${_sub}/${_subItem}`} className='text-deco-none'>
                                                        <div className='syd-box small syd-box-service'>
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <div>
                                                                    <h5 className='syd-title small light'>{TranslationsService.labels('services')[area].items[_sub][_subItem].title}</h5>
                                                                </div>
                                                                <div>
                                                                    <svg viewBox="0 0 10 11.55" className='box-arrow-item'>
                                                                        <polygon className="arrow-item" points="10 5.77 0 0 0 11.55 10 5.77"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <p className='syd-paragraph' dangerouslySetInnerHTML={{ __html: TranslationsService.labels('services')[area].items[_sub][_subItem].desc }}></p>
                                                        </div>
                                                    </Link>
                                                </div>
                                                ) : null
                                            ))
                                            ) : null
                                        }
                                        </div> */}
                                    </div>
                                ))
                                )
                                :
                                (
                                <div className='container px-md-5'>
                                    <div className='row'>
                                    {
                                        Object.keys(servicesList[area]).map((subbu,i) => (
                                        servicesList[area][subbu].title && 
                                            <div className='col-md-4 col-sm-12 p-4' key={i}>
                                                <div className='syd-card-container'>
                                                  <div className='image-background' style={{backgroundImage: `url(${servicesList[area][subbu].image})`}}/>
                                                  <div className='syd-content-card'>
                                                    <h4>{servicesList[area][subbu].title}</h4>
                                                    <Link to={`/services/${area}/${subbu}`} className='text-deco-none'>
                                                      <div className='syd-button-card'>
                                                        {TranslationsService.labels('explore_more')}
                                                      </div>
                                                    </Link>
                                                  </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {/* {
                                        TranslationsService.labels('services')[area].items[_sub] ?
                                        (
                                        Object.keys(TranslationsService.labels('services')[area].items[_sub]).map((_subItem, j) => (
                                            typeof TranslationsService.labels('services')[area].items[_sub][_subItem] === 'object' &&
                                            TranslationsService.labels('services')[area].items[_sub][_subItem].title ?
                                            (
                                            <div className='col-md-4 col-sm-12 p-4' key={j}>
                                                <div className='syd-card-container'>
                                                  <div className='image-background' style={{backgroundImage: `url(${TranslationsService.labels('services')[area].items[_sub][_subItem].image})`}}/>
                                                  <div className='syd-content-card'>
                                                    <h5>{TranslationsService.labels('services')[area].items[_sub][_subItem].title}</h5>
                                                    <Link to={`/services/${area}/${_sub}/${_subItem}`} className='text-deco-none'>
                                                      <div className='syd-button-card'>
                                                        {TranslationsService.labels('explore_more')}
                                                      </div>
                                                    </Link>
                                                  </div>
                                                </div>
                                            </div>
                                            ) : null
                                        ))
                                        ) : null
                                    } */}
                                    </div>
                                </div>

                               
                                )
                            }
                        </div>
                    </div>
                    );
                })
            }
        </div>
    </div>
  );
};